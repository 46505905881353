<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :span="6">
        <el-input v-model="keyword"
                  style="width:100%"
                  placeholder="请输入" />
      </el-col>
      <el-col :span="12">
        <el-button type="primary"
                   @click="search">查询</el-button>
        <el-button type="primary"
                   @click="addGroup">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Form ref="FormRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Form from './components/form.vue'
import { getSchoolGroup, getGroupInfo } from '@/api/school_group.js'
export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      showList: [],
      keyword: '',
      pageName: 'school_group',
      tableColumns: [
        { prop: 'school_group_id', align: 'center', label: '编号', width: '' },
        { prop: 'group_name', align: 'center', label: '分组名称', width: '' },
        { prop: 'school_names', align: 'center', label: '学校', width: '' },
        { prop: 'create_time', align: 'center', label: '创建时间', width: '' },
        { prop: 'status', align: 'center', label: '是否启用', width: '', isStatus: true },

        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '修改', type: 'edit', event: 'showForm' },
            { content: '删除', type: 'del', event: 'delRow' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      // 根据不同字段做处理
      if (prop == 'status') {
        return value ? 'notpass' : 'pass'
      }
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '否' : '是'
      }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    async initData (page, limit) {
      let params = {
        page,
        limit,
        keyword: this.keyword
      }
      const { data } = await getSchoolGroup(params)
      this.tableData = data.list
      this.totalElements = data.count
    },

    delRow (row) {
      this.$confirm("此操作将删除该条数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$http({
          url: '/api/v2/paper/school_group_del',
          method: 'post',
          data: {
            school_group_id: row.school_group_id
          }
        })
      }).then(() => {
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        setTimeout(() => {
          this.refresh()
        }, 500);
      })
    },
    addGroup () {
      let form = {
        group_name: '',
        school_ids: []
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.text = '新增'
      this.$refs.FormRef.dialogVisible = true
    },
    async showForm (row) {
      let params = {
        school_group_id: row.school_group_id
      }
      const { data } = await getGroupInfo(params)

      let form = {
        group_name: row.group_name,
        school_ids: data.list.map(item => item.school_id),
        school_group_id: row.school_group_id,
        status: row.status
      }
      this.$refs.FormRef.form = form
      this.$refs.FormRef.text = '修改'
      this.$refs.FormRef.dialogVisible = true
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
