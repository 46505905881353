<template>
  <div>
    <el-dialog :title="text+'分组'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">
      <el-form :model="form"
               ref="formRef"
               :rules="rules"
               label-width="90px">
        <el-row :gutter="10">
          <el-col :span="24">
            <el-form-item label="分组名称"
                          prop="group_name">
              <el-input placeholder="请输入分组名称"
                        v-model="form.group_name"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <el-form-item label="学校"
                          prop="school_ids">
              <el-select v-model="form.school_ids"
                         style="width:100%"
                         filterable
                         multiple
                         placeholder="请选择">
                <el-option v-for="item in schoolList"
                           :key="item.school_id"
                           :label="item.school_name"
                           :value="item.school_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24"
                  v-if="text == '修改'">
            <el-form-item label="是否启用"
                          prop="status">
              <el-radio-group v-model="form.status">
                <el-radio :label="0">开启</el-radio>
                <el-radio :label="1">关闭</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

      </el-form>

      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="save">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getStartSchool } from '@/api/school.js'
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      schoolList: [],
      text: '',
      rules: {
        group_name: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        school_ids: [
          { required: true, message: '请选择', trigger: 'change' },
        ]
      },
      checkList: []
    }
  },
  mounted () {
    this.initData()
  },
  methods: {
    async initData () {
      const { data } = await getStartSchool()
      this.schoolList = data.list
    },
    handleClose () {
      this.$refs.formRef.resetFields()
      this.dialogVisible = false
    },
    save () {
      this.$refs.formRef.validate(val => {
        if (!val) return
        this.$http({
          url: '/api/v2/paper/school_group_create',
          method: 'post',
          data: this.form
        }).then(res => {
          this.$notify({
            title: '提示',
            message: '修改成功!',
            type: 'success'
          });
          this.$parent.refresh()
          this.handleClose()
        })

      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
::v-deep .el-dialog__footer {
  text-align: center;
}
.toopic {
  font-size: 12px;
  line-height: 14px;
  span {
    margin-left: -12px;
    display: inline-block;
    width: 100%;
    width: 305px;
    color: #ff8383;
    transform: scale(0.9);
  }
}

::v-deep .el-dialog {
  border-radius: 15px;
}
.el-tabs {
  margin-left: 20px;
}
::v-deep .el-tabs__nav-wrap::after {
  background: none;
}
</style>